.container {
  padding-left: 100px;
  padding-right: 100px;
  background-image: url("https://cdn.glitch.global/87b890ca-6106-49ca-aa8a-61c4d3b89a53/sushi%20bg-app.jpg?v=1693469701897");
  background-repeat: repeat;
}

h1 {
  text-align: center;
  margin-bottom: 30px;  
  color: #dc3545;
  margin-top: 10px; 
  font-size: 50px;
}

img.logo {
  max-width: 20%;
  height: auto;
  display: block;
  margin: 0 auto;
  margin-top: 30px;
} 



/* ******************************** responsive ************************************************ */


@media only screen and (max-width: 768px) {

  img.logo {
    max-width: 60%;
  } 

  h1 {
    font-size: calc(clamp(3.25em, 7vw, 8em) * .875);
    margin-bottom: 1em;  
  }
  .container {
    padding-left: 50px;
    padding-right: 50px;
    background-image: url("https://cdn.glitch.global/87b890ca-6106-49ca-aa8a-61c4d3b89a53/sushi%20bg-app.jpg?v=1693469701897");
    background-repeat: repeat;
  }

}



/* ********************************** responsive ********************************************** */