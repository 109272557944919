/* ------------------------- Style ® Code by Panneberry -------------------------------------------------- */

/* Add font styles */


/* _____________________________________panne WIP */
main {
  box-sizing: border-box;
  overflow: hidden;
  width: 100vw;
  position: fixed;
  height: 100%;
  background: var(--color-white);
}

.main-wrap::after {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 5vh;
  top: 99%;
  left: 0;
  background: var(--color-dark);
  z-index: -1;
}

.main-wrap {
  box-sizing: border-box;
  width: 100vw;
  will-change: transform;
  position: fixed;
}

main.touch {
  position: relative;
  height: auto;
}

main.touch .main-wrap {
  position: relative;
}

@media screen and (max-width: 1024px) {
  main, .main-wrap {
      position: relative;
  }
}

/* ------------------------- Page Transition -------------------------------------------------- */

.no-scroll-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 900;
  display: none;
}

.loading-container {
  /* background-color: red; */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 800;
  pointer-events: none;
  background: transparent;
}

.loading-screen {
  position: relative;
  padding: 0;
  width: 100%;
  height: 100%;
  left: 0;
  will-change: top;
  background: var(--color-dark-dark);
}

.loading-container .rounded-div-wrap {
  position: absolute;
  z-index: 2;
  height: 0vh;
  will-change: height;
}

.loading-container .rounded-div-wrap.top {
  top: 0;
  transform: translateY(-99%) scale(1, -1);
}

.loading-container .rounded-div-wrap.bottom {
  bottom: 0;
  transform: translateY(99%);
}

.loading-container .loading-words .home-active,
.loading-container .loading-words .home-active-last {
    opacity: 0;
    display: none;
}

.loading-fade {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 90;
  background: #FFF;
  visibility: hidden;
  opacity: 0;
}

.once-in {
  will-change: transform;
}

@media screen and (max-width: 540px) {
  .loading-container .dot {
      top: 42%;
  }
}

.loading-container .loading-words {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  pointer-events: none;
  display: block;
  will-change: opacity;
}

.loading-container .loading-words h2 {
  margin: 0;
  color: var(--color-white);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: none;
  white-space: nowrap;
  will-change: opacity;
}

.loading-container .loading-words h2.jap {
  font-size: calc(clamp(3.25em, 5vw, 4.5em) * 0.65);
  transform: translate(-50%, 10%);
}

.loading-container .loading-words h2.active {
  display: block;
}

/* ------------------------- Top Bar -------------------------------------------------- */

.nav-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  z-index: 5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: calc(var(--gap-padding) / 2);
}

.nav-bar .links-wrap li:last-child {
  display: none;
}

@media screen and (max-width: 540px) {
  .nav-bar {
      padding: calc(var(--gap-padding) / 1);
  }
  .nav-bar .links-wrap li {
      display: none;
  }
  .nav-bar .links-wrap li:last-child {
      display: block;
  }
}

/* ------------------------- Footer -------------------------------------------------- */

.footer-wrap {
  width: 100%;
  position: relative;
  overflow: hidden;
  background: var(--color-dark-dark);
  display: flex;
  align-items: flex-end;
  box-shadow: 0px 5px 0px 5px var(--color-dark);
}

.footer {
  padding-bottom: 0;
  width: 100%;
  will-change: transform;
}

.bottom-footer {
  padding: calc(var(--section-padding) / 1.3) calc(var(--gap-padding) / 1.33);
  display: flex;
  justify-content: space-between;
}

/* ------------------------- Home - Header -------------------------------------------------- */

.home-header {
  min-height: 115vh;
  display: flex;
  align-items: center;
  background: var(--color-gray);
  overflow: hidden;
}

.home-header .big-name {
  position: absolute;
  bottom: 15vh;
  left: 0;
  width: 100%;
  padding-bottom: calc(var(--gap-padding) * 1.25);
}

.home-header .big-name h1 {
  display: flex;
  align-items: center;
  color: var(--color-white);
  line-height: 1;
  font-size: max(9em, 15vw);
  white-space: nowrap;
  pointer-events: none;
  margin: 0;
  padding: 0;
}

/* ------------------------- Work Grid -------------------------------------------------- */

.work-grid .work-items li {
  transition: var(--animation-smooth);
}

.work-grid .work-items a:hover h4 {
  transform: translateX(calc(var(--container-padding) * -0.1));
}

.work-grid .work-items a:hover p {
  transform: translateX(calc(var(--container-padding) * 0.1));
}

/* ------------------------- General Styles and Utilities -------------------------------------------------- */

.grid-fade {
  height: 0;
  overflow: hidden;
  transition: opacity 0.3s ease-in 0.4s, transform 1s cubic-bezier(0.16, 1, 0.3, 1);
}

.grid-fade.visible {
  height: auto;
}

.mouse-pos-list-image {
  position: fixed;
  pointer-events: none;
  transition: width 0.4s cubic-bezier(0.36, 0, 0.66, 0);
}
