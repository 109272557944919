h2 {
    font-size: 16px;
}

#totalPrice,
#serviceCharge,
#totalPriceWithServiceCharge {
  margin-top: 20px;
  /* padding-left: 31.5%; */
  font-size: 100%;
    
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  text-align: right;
  padding-inline: 28%;
  font-weight: bold;

}

.actionButtonClass{
  display: flex;
  justify-content: flex-end; 
  align-items: center; 
  flex-direction: column; 
  font-weight: bold;
  margin-bottom: 10px;
}

.totalPersonClass{
  display: flex;
  align-items: center; 
  flex-direction: column; 
  /* align-items: flex-start; */
  font-weight: bold;
  margin-bottom: 10px;
}


.dish {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
}

/* ******************************** responsive ************************************************ */

@media only screen and (max-width: 768px) {

#totalPrice,
#serviceCharge,
#totalPriceWithServiceCharge {
  padding-inline: unset;
}

.mobileNextPerson{
  font-size: 80%;
}
}
