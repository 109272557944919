.eachDishCenter {
  display: flex;
  justify-content: flex-end; 
  align-items: center; 
  flex-direction: column; 
  font-size: x-large;
  font-weight: bold;
  margin-bottom: 10px;
}
.dish {
  display: flex;
  align-items: center;
}
.dish button {
    touch-action: manipulation;
    margin: 0 5px;
    font-size: 80%;
    background-color: #dc3545;
    color: white;
    border-radius: 10px;
    width: 200px;
    height: 50px;
    font-weight: bold;
  }

  .dish40 button {
    background-color: #dc3545;
  }
  
  .dish60 button {
    background-color: #acdcfb;
  }
  
  .dish80 button {
    background-color: #fcb900;
  }
  
  .dish100 button {
    background-color: #dc3545;
  }
  
  .dish120 button {
    background-color: #2d2d2d;
  }
  
  .dish160 button {
    background-color: #000000;
  }

  .dish40 span,
.dish60 span,
.dish80 span,
.dish100 span,
.dish120 span,
.dish160 span  {
  flex-grow: 1;
  margin: 0 5px;
  font-weight: bold;
}



/* ******************************** responsive ************************************************ */

@media only screen and (max-width: 768px) {
  
  .eachDishCenter {
  display: flex;
  justify-content: flex-start; 
  align-items: center; 
  flex-direction: column; 
  font-size: large;
  font-weight: bold;
  margin-bottom: 10px;
  }

  .middle-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%; 
    height: 40px; 
    background-color: white; 
  }

  .dish {
    display: flex;
    align-items: center;
  }
  .dish button {
      touch-action: manipulation;
      margin: 0 5px;
      font-size: 80%;
      background-color: #dc3545;
      color: white;
      border-radius: 10px;
      width: 100px;
      height: 50px;
      font-weight: bold;
    }
  
    .dish40 button {
      background-color: #dc3545;
    }
    
    .dish60 button {
      background-color: #acdcfb;
    }
    
    .dish80 button {
      background-color: #fcb900;
    }
    
    .dish100 button {
      background-color: #dc3545;
    }
    
    .dish120 button {
      background-color: #2d2d2d;
    }
    
    .dish160 button {
      background-color: #dc3545;
    }
  
    .dish40 span,
  .dish60 span,
  .dish80 span,
  .dish100 span,
  .dish120 span,
  .dish160 span  {
    flex-grow: 1;
    margin: 0 5px;
    font-weight: bold;
  }
}